<template>
  <div class="container">
      <SecondNav></SecondNav>
      <RaisingNav></RaisingNav>
    <div>
      <div class="list_banner">
            <ul class="flex">
                <li>
                    <img src="../../../assets/img/zhu_zixun_list1.png" alt="">
                    <h2>2020国鸡产业高峰论坛暨新禽况年会颁奖典礼</h2>
                </li>
                <li>
                    <img src="../../../assets/img/zhu_zixun_list2.png" alt="">
                    <h2>礼蓝定制班•第二期家禽首席兽医官特训营</h2>
                </li>
                <li>
                    <img src="../../../assets/img/zhu_zixun_list3.png" alt="">
                    <h2>2020第二届穗风饲料营养论坛（云会议）</h2>
                </li>
                <li>
                    <img src="../../../assets/img/zhu_zixun_list4.png" alt="">
                    <h2>硕腾中国•腾云护猪行动</h2>
                </li>
            </ul>
      </div>
      <div class="list_content">
            <div>
                <h1>猪业资讯</h1>
            </div>
            <div>
                <ul>
                    <li v-for="(item,idx) in list" :key="idx">
                        <div class="flex">
                            <h1>猪业资讯</h1>
                            <h2>{{item.title}}</h2>
                        </div>
                        <p>
                            <span>{{item.createTime}}</span>
                            <span class="segmentation">|</span>
                            <span>{{item.createUser}}</span>
                        </p>
                        <p v-html="item.content">{{item.content}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getColumnID, getColList } from '@/api/pigindustryinformation'
import SecondNav from '../../about/components/secondNav'
import RaisingNav from '../components/raisingNav.vue'
export default {
  components: {
    SecondNav,
    RaisingNav
  },
  data () {
    return {
      list: ''
    }
  },
  methods: {
    getpigprico () {
      getColumnID({
        level: 3
      }).then((res) => {
        const cid = res.data.records[1].id
        getColList({
          columnId: cid
        }).then((res) => {
            console.log(res.data.records)
          this.list = res.data.records
        })
      })
    }
  },
  mounted () {
    this.getpigprico()
  }
}
</script>

<style>
.main1{
    margin-top: 20px;
    text-align: left;
    border-bottom:1px solid #dcdcdc;
}
.main1 li{
    padding:8px 18px;
    text-align: center;
    background-color: #F0F9EB;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
}
.main1 .active a {
    color: #fff;
}
.main1 a{color:#666;}
.main1 .active{
    background-color: #93b067;
    color: #fff;
}
.list_banner{
    width: 100%;
}
.list_banner ul{
    margin: 40px 0;
}
.list_banner li{
    width: 237px;
}
.list_banner li+li{
    margin-left: 80px;
}
.list_banner li h2{
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin-left: 0;
}
.list_banner li img{
    border: 1px solid #999;
    padding: 2px;
}
.list_content{
    text-align: left;
}
.list_content>div:nth-child(1){
    border-bottom: 1px solid #dcdcdc;
}
.list_content>div:nth-child(1) h1{
    width: 200px;
    border-bottom: 3px solid #93b067;
    font-weight: bold;
    text-align: center;
    color:#93b067;
    padding-bottom: 10px;
}
.list_content>div:nth-child(2) li{
    padding: 20px 0;
    padding-left: 20px;
    margin:0 20px;
    border-bottom: 1px dashed #ddd;
    cursor: pointer;
}
.list_content>div:nth-child(2) div h1,h2{
    font-size: 20px;
    margin-left: 20px;
}
.list_content>div:nth-child(2) div h1{
    position: relative;
    color: #93b067;
    font-weight: bold;
}
.list_content>div:nth-child(2) div h1::before{
    content: "";
    width: 24px;
    height: 28px;
    position: absolute;
    background: url(../../../assets/img/u166.svg) no-repeat;
    left: -30px;
    top: 0;
}
.list_content>div:nth-child(2) p{
    margin-top: 10px;
    line-height: 25px;
}
.segmentation{
   margin: 0 10px;
}
</style>
